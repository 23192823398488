
import ServiceDot from "@/static/icons/service-dot.svg";
import ServiceStar from "@/static/icons/service-star.svg";
export default {
  name: "homeDeals",
  components: { ServiceDot, ServiceStar },
  data() {
    return {
      services: [
        {
          title: "Quality Food",
          details:
            "Departure defective arranging rapturous did. Conduct denied adding worthy little.",
          icon: "fas fa-utensils text-orange-500 bg-orange-100",
        },
        {
          title: "Quick Services",
          details: "Supposing so be resolving breakfast am or perfectly.",
          icon: "fas fa-stopwatch text-red-500 bg-red-100",
        },
        {
          title: "High Security",
          details: "Arranging rapturous did believe him all had supported.",
          icon: "fa-solid fa-shield-halved text-green-500 bg-green-100",
        },
        {
          title: "24 Hours Alert",
          details: "Rapturous did believe him all had supported.",
          icon: "fa-solid fa-bolt text-cyan-500 bg-cyan-100",
        },
      ],
    };
  },
};
